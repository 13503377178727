import React from 'react';
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import {validateForm} from "../../../../util/validation";
import Form from "../../form";
import TextInput from "../../../atoms/text-input";
import InputStack from "../../input-stack";
import Checkbox from "../../../atoms/checkbox";
import TagInput from "../../../atoms/tag-input";

class ReleaseStockEdit extends React.Component {
    static defaultProps = {
        release: {},
        onCancel: () => {
        },
        onSave: () => {
        }
    };

    constructor(props) {
        super(props);

        this.state = {
            release: this.props.release,
            formats: [],
        };

        this.form = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.release && (prevProps.release !== this.props.release)) {
            this.fetchRelease();
        }

        if (!this.state.formats.length) {
            this.fetchFormats();
        }
    }

    componentDidMount() {
        this.fetchRelease();
        this.fetchFormats();
    }

    fetchRelease() {
        http
            .request({url: API_HOST + API_ROOT + 'releases/' + this.props.release.id}, true)
            .then(response => {
                this.setState({
                    release: response.result
                });
            });
    }

    fetchFormats() {
        http
            .request({url: API_HOST + API_ROOT + 'releases/formats'}, true)
            .then(response => {
                this.setState({
                    formats: response.result.items.map(format => {
                        return {
                            value: format.id + '',
                            label: format.abbreviation || format.name,
                            selected: this.state.release.formats && this.state.release.formats.filter(selectedFormat => {
                                return selectedFormat.id === format.id;
                            }).length > 0,
                        };
                    }),
                });
            });
    }

    validateForm(form, data) {
        validateForm(form, data, {
            price: ['required', 'number'],
            sale_price: ['number'],
            stock: ['required'],
            stock_2: ['required'],
        });
    }

    onSubmit(data) {
        console.log(data)

        http
            .patch(API_HOST + API_ROOT + 'releases/' + this.props.release.id, data, true)
            .then(response => {
                if (response.success) {
                    this.props.onSave(response.result);
                }
            })
            .catch(error => {
                alert(error);
            });
    }

    render() {
        if (!this.state.release) {
            return;
        }

        return (
            <React.Fragment>
                <Form validate={(form, data) => this.validateForm(form, data)}
                      onSubmit={(data) => this.onSubmit(data)}
                      ref={this.form}
                >
                    <InputStack>
                        <TextInput name="price"
                                   label="Price"
                                   showRequiredIndicator={true}
                                   focus={true}
                                   default={this.state.release.price}
                        />
                        <InputStack modifiers={'alternate-direction'}>
                            <Checkbox name="on_sale" label="On sale" default={this.state.release.on_sale}/>
                            <TextInput name="sale_price"
                                       label="Sale price"
                                       showRequiredIndicator={true}
                                       default={this.state.release.sale_price}/>
                        </InputStack>
                    </InputStack>

                    <InputStack modifiers={'alternate-direction'}>
                        <TagInput
                            name="formats"
                            label="Formats"
                            autocomplete={true}
                            suggestions={this.state.formats}
                        />
                    </InputStack>

                    {/*<InputStack title={'Stock'} modifiers={'alternate-direction'}>*/}
                    {/*    <InputStack>*/}
                    {/*        <InputStack modifiers={'alternate-direction'}>*/}
                    {/*            <TextInput name="stock" label="Stock amount" type={'number'}*/}
                    {/*                       showRequiredIndicator={true} default={this.state.release.stock || '0'}/>*/}
                    {/*            <TextInput name="stock_alert_limit" type={'number'} showRequiredIndicator={true}*/}
                    {/*                       label="Stock alert" default={this.state.release.stock_alert_limit}/>*/}
                    {/*        </InputStack>*/}
                    {/*        <InputStack modifiers={'alternate-direction'}>*/}
                    {/*            <TextInput name="stock_2" label="Offline stock" type={'number'}*/}
                    {/*                       showRequiredIndicator={true} default={this.state.release.stock_2 || '0'}/>*/}
                    {/*            <TextInput name="stock_2_alert_limit" type={'number'} showRequiredIndicator={true}*/}
                    {/*                       label="Offline stock alert"*/}
                    {/*                       default={this.state.release.stock_2_alert_limit}/>*/}
                    {/*        </InputStack>*/}
                    {/*    </InputStack>*/}
                    {/*</InputStack>*/}

                    <InputStack title={'Preorder'} modifiers={'alternate-direction'}>
                        <InputStack>
                            <Checkbox name="is_preorderable" label="Is preorderable"
                                      default={this.state.release.is_preorderable}/>
                            <InputStack modifiers={'alternate-direction'}>
                                <TextInput name="preorder_amount" type={'number'} showRequiredIndicator={true}
                                           label="Preorder amount" default={this.state.release.preorder_amount}/>
                                <TextInput name="preorder_limit" type={'number'} showRequiredIndicator={true}
                                           label="Preorder limit" default={this.state.release.preorder_limit}/>
                            </InputStack>
                        </InputStack>
                    </InputStack>

                </Form>
            </React.Fragment>
        );
    }
}

export default ReleaseStockEdit;