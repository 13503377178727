import './index.scss';

import React from 'react';
import modifiers from "../../../util/components";

export default class TextInput extends React.Component {

    static defaultProps = {
        default: '',
        label: '',
        name: '',
        modifiers: null,
        disabled: false,
        placeholder: '',
        showRequiredIndicator: false,
        focus: false,
        type: 'text',
        step: '0.01',
        max: null,
        onChange: () => {},
        onBlur: () => {},
        onFocus: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            id: 'input-' + this.props.name + '-' + Math.random().toString(36).substring(7),
            value: this.props.default
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.default !== prevProps.default) {
            this.setState({
                value: this.props.default
            });
        }
    }

    getValue() {
        return this.state.value;
    }

    handleChange(e) {
        let value = e.target.value;
        
        if (this.props.type === 'number' && this.props.max && value > this.props.max) {
            value = this.props.max;
        }
        
        this.setState({value: value});
        this.props.onChange(this.state.value);
    }

    updateValue(value = '') {
        this.setState({value: value});
    }

    handleSubmit(data) {
        data[this.props.name] = this.getValue();
    }

    render() {
        let label;
        let requiredIndicator;

        if (this.props.showRequiredIndicator) {
            requiredIndicator = <span className="text-input__required-indicator">*</span>;
        }

        if (this.props.label) {
            label = (
                <label htmlFor={this.state.id} className="text-input__label">
                    {this.props.label}
                    {requiredIndicator}
                </label>
            );
        }

        return (
            <div className={"text-input" + modifiers('text-input', this.props.modifiers)}>
                {label}
                {this.props.type === 'number' && (
                    <input id={this.state.id}
                           name={this.props.name}
                           className="text-input__input"
                           type={this.props.type}
                           step={this.props.step}
                           max={this.props.max}
                           value={this.state.value}
                           autoComplete={'off'}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           onChange={this.handleChange}
                           onKeyUp={this.handleChange}
                           onBlur={e => this.props.onBlur(e)}
                           onFocus={e => this.props.onFocus(e)}
                           autoFocus={this.props.focus}
                    />
                )}
                {this.props.type !== 'number' && (
                    <input id={this.state.id}
                           name={this.props.name}
                           className="text-input__input"
                           type={this.props.type}
                           value={this.state.value}
                           autoComplete={'off'}
                           placeholder={this.props.placeholder}
                           disabled={this.props.disabled}
                           onChange={this.handleChange}
                           onKeyUp={this.handleChange}
                           onBlur={e => this.props.onBlur(e)}
                           onFocus={e => this.props.onFocus(e)}
                           autoFocus={this.props.focus}
                    />
                )}
                
            </div>
        );
    }
}