import { API_HOST, API_ROOT, http } from "../../../../util/http";

import React from 'react';
import ui from "../../../../util/ui";
import { validateForm } from "../../../../util/validation";

import Form from "../../form";
import SelectInput from "../../../atoms/select-input";
import Loader from "../../../atoms/loader";

class PickListCreate extends React.Component {
    static defaultProps = {
        onSave: () => {}
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            locations: [],
            loading: false,
        };
        
        this.form = React.createRef();
    }
    
    componentDidMount() {
        http
            .get(API_HOST + API_ROOT + 'locations', true)
            .then(response => {
                this.setState({
                    locations: response.result
                });
            });
    }
    
    validateForm(form, data) {
        validateForm(form, data, {
            location: ['required']
        });
    }
    
    onSubmit(data) {
        this.setState({
            loading: true,
        });
        
        http
            .post(API_HOST+API_ROOT + 'pick_lists', data, true)
            .then(response => {
                this.setState({
                    loading: false,
                });
                
                if (response.success) {
                    this.props.onSave(response.result);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                });
                
                ui.notify('There was an error, pick list couldn\'t be created', 'error');
            });
    }
    
    render() {
        let options = {};
        
        this.state.locations.forEach(location => {
            options[location.id] = location.name;
        });
        
        return (
            <div>
                <Form
                    ref={this.form}
                    validate={(form, data) => this.validateForm(form, data)}
                    onSubmit={(data) => this.onSubmit(data)}
                    submitButtonText={'Create'}
                >
                    <SelectInput
                        name="location"
                        label="Location"
                        showRequiredIndicator={true}
                        options={options}
                        disabled={this.state.loading}
                    />
                </Form>
                {this.state.loading && (
                    <div className="content-loader">
                        <Loader isLoading={true} size="large"/>
                    </div>
                )}
            </div>
        );
    }
}

export default PickListCreate;