import './index.scss';

import React from 'react';
import modifiers from '../../../util/components';

export default class Checkbox extends React.Component {

  static defaultProps = {
    label: '',
    name: '',
    default: false,
    disabled: false,
    modifiers: null,
    onChange: () => {}
  };

  constructor(props) {

    super(props);
    
    this.state = {
      id: 'input-'+this.props.name+'-'+Math.random().toString(36).substring(7),
      value: this.props.default
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {

    if (this.props.default !== prevProps.default) {
      this.setState({ value: this.props.default });
    }
  }

  setValue(value) {

    this.setState({ value: value });
  }

  getValue() {

    return this.state.value;
  }

  handleChange(e) {
    if (this.props.disabled) {
      return;
    }

    this.setState({value: e.target.checked});
    this.props.onChange(e.target.checked);
  }

  handleSubmit(data) {

    data[this.props.name] = this.getValue();
  }

  render() {

    let label;

    if (this.props.label) {
      label = (
        <label htmlFor={this.state.id} className="checkbox__label">
          {this.props.label}
        </label>
      );
    }

    return (
      <div className={"checkbox"+modifiers('checkbox', this.props.modifiers)}>
        <input id={this.state.id}
               name={this.props.name}
               className="checkbox__field"
               type="checkbox"
               checked={this.state.value}
               disabled={this.state.disabled}
               onChange={this.handleChange}
        />
        {label}
      </div>
    );
  }
}