import connect from "react-redux/es/connect/connect";
import {API_HOST, API_ROOT, http} from "../../../../util/http";
import {
    addProduct,
    checkoutInventory,
    deleteProduct, emptyInventory,
    updateProduct, updateProductFormats,
    updateProductLocationAmount
} from "../../../../state/modules/inventory";

import React from "react";
import {validateForm} from "../../../../util/validation";
import ui from "../../../../util/ui";

import ModuleView from "../../module-view/index";
import List from "../../list";
import ReleaseListItem from "../release-list-item";
import Window from "../../window";
import Search from "../../search";
import Pagination from "../../pagination";
import ButtonGroup from "../../button-group";
import Button from "../../../atoms/button";
import Popup from "../../popup";
import BarcodeScanner from "../../barcode-scanner";
import Grid from "../../grid";
import TextInput from "../../../atoms/text-input";
import Form from "../../form";
import InventoryListItem from "../inventory-list-item";
import EnumSwitch from "../../enum-switch";
import LoadingOverlay from "../../loading-overlay";
import {handleTokenErrors} from "../../../../state/modules/errors";
import ReleaseStockEdit from "../release-stock-edit";
import TagInput from "../../../atoms/tag-input";
import {format, parse} from "path";
import ItemSelectPopup from "../../item-select-popup";
import ReleaseCompactItem from "../release-compact-list-item";
import PickListCreate from "../pick-list-create";
import PickListRow from "../pick-list-row";
import PickLists from "../pick-lists";
import list from "../../list";
import PickItemsList from "../pick-items-list";
import Loader from "../../../atoms/loader";

class InventoryModule extends ModuleView {

    locations = [
        {
            key: 'stock_2',
            label: 'Offline stock'
        },
        {
            key: 'stock',
            label: 'Online stock'
        }
    ];

    constructor(props) {
        super(props);
        
        this.views = {
            index: (
                <Window
                    title="Inventory"
                    header={
                        <ButtonGroup>
                            <Button onClick={e => this.updateView()} text={'Update stock'}/>
                            <Button onClick={e => this.updateView('picker')} text={'Picker'}/>
                            <Search listId="releases"/>
                        </ButtonGroup>
                    }
                >
                    <Window modifiers="depth" footer={<Pagination listId="releases"/>}>
                        <List id={'releases'} model={'releases'} selectable={false}>
                            <ReleaseListItem
                                onManageStock={(release) => this.openPopup('releaseStockUpdate', release, release.id)}/>
                        </List>
                    </Window>
                </Window>
            ),
            picker: (
                <Window
                    title="Picking Lists"
                    header={
                        <ButtonGroup>
                            <Button onClick={e => this.openPopup('createPickList')} text={'Create list'} />
                            <Button onClick={e => this.updateView('index')} text={'Back to Inventory'} modifiers="alt" />
                        </ButtonGroup>
                    }
                >
                    <PickLists onViewList={list => this.openPopup('viewPickList', list)} />
                </Window>
            ),
        };

        this.popups = {
            discogsIdSearch: () => {

                return (
                    <Popup title="Search by discogs id">
                        <Form
                            validate={(form, data) => validateForm(form, data, {discogsId: ['required']})}
                            submitButtonText={'Search'}
                            onSubmit={data => {

                                let discogsId = data.discogsId;
                                let release = this.findValue(this.props.products, 'discogs_id', discogsId);

                                if (release) {

                                    this.props.addProduct(release, this.state.activeLocation);
                                    this.closePopup('discogsIdSearch');
                                    return;
                                }

                                this.toggleLoading();

                                http.get(API_HOST + API_ROOT + 'releases/findbydiscogsid', true, {discogsId: discogsId}).then(response => {

                                    let discogsRelease = response.result;
                                    this.props.addProduct(discogsRelease, this.state.activeLocation);
                                    this.toggleLoading();
                                    this.closePopup('discogsIdSearch');

                                }).catch(error => {

                                    if (error.message === 'release_not_found') {

                                        http.get(API_HOST + API_ROOT + 'discogs/id/' + discogsId, true).then(response => {

                                            let discogsRelease = response.result;
                                            this.props.addProduct(discogsRelease);
                                            this.toggleLoading();
                                            this.closePopup('discogsIdSearch', '', () => {
                                                this.openPopup('discogsReleaseCreate', discogsRelease, discogsRelease.barcode);
                                            });

                                        }).catch(error => {

                                            this.toggleLoading();

                                            if (error.message === 'release_not_found') {
                                                ui.notify('Discogs release not found', 'error');
                                            }
                                        });

                                    }
                                })
                                    .catch(error => handleTokenErrors(error));
                            }}
                        >
                            <TextInput name={'discogsId'} label={'Discogs id'} showRequiredIndicator={true}
                                       focus={true}/>
                        </Form>
                    </Popup>
                );
            },
            discogsReleaseCreate: (discogsRelease) => {

                return (
                    <Popup title={'Add: ' + discogsRelease.title}>
                        <Form
                            validate={(form, data) =>
                                validateForm(form, data, {
                                    price: ['required', 'number'],
                                    // stock: ['required'],
                                    // stock_2: ['required'],
                                })
                            }
                            submitButtonText={'Create'}
                            onSubmit={data => {
                                console.log(data);

                                discogsRelease.price = data.price;
                                discogsRelease[this.state.activeLocation] = 0;
                                discogsRelease.formats = data.formats;

                                this.props.addProduct(discogsRelease, this.state.activeLocation);
                                this.props.updateProductFormats(discogsRelease, data.formats);
                                this.props.updateProductLocationAmount(discogsRelease, 'stock', data.stock);
                                this.props.updateProductLocationAmount(discogsRelease, 'stock_2', data.stock_2);

                                this.closePopup('discogsReleaseCreate', discogsRelease.barcode);
                                this.toggleScanning();
                            }}
                        >
                            <TextInput name="price" label="Price" showRequiredIndicator={true} focus={true}/>
                            {/*<TextInput name="stock_2" type={'number'} label="Offline stock" showRequiredIndicator={true}*/}
                            {/*           default={'0'}/>*/}
                            {/*<TextInput name="stock" type={'number'} label="Online stock" showRequiredIndicator={true}*/}
                            {/*           default={'0'}/>*/}
                            <TagInput
                                name="formats"
                                label="Formats"
                                autocomplete={true}
                                suggestions={this.state.formats}
                            />
                        </Form>
                    </Popup>
                );
            },
            productStockUpdate: (product) => {

                let formats = this.state.formats.map(format => {
                    return {
                        value: format.value,
                        label: format.name,
                        selected: product.formats ? product.formats.filter(selectedFormat => {
                            return parseInt(selectedFormat.id) === parseInt(format.value);
                        }).length > 0 : false
                    };
                });

                return (
                    <Popup title={'Edit product'}>
                        <Form
                            validate={(form, data) => {
                                validateForm(form, data, {
                                    price: ['required', 'number'],
                                    stock: ['required'],
                                    stock_2: ['required'],
                                })
                            }}
                            submitButtonText={'Update'}
                            onSubmit={data => {
                                this.props.updateProduct({...product, ...{price: data.price}});
                                this.props.updateProductFormats(product, data.formats);
                                this.props.updateProductLocationAmount(product, 'stock', data.stock);
                                this.props.updateProductLocationAmount(product, 'stock_2', data.stock_2);
                                this.closePopup('productStockUpdate');
                            }}
                        >
                            <TextInput name="price" label="Price" showRequiredIndicator={true} focus={true}
                                       default={product.price}/>
                            {/*<TextInput name="stock_2" type={'number'} label="Offline stock" showRequiredIndicator={true}*/}
                            {/*           default={product.amount.stock_2 || '0'}/>*/}
                            {/*<TextInput name="stock" type={'number'} label="Online stock" showRequiredIndicator={true}*/}
                            {/*           default={product.amount.stock || '0'}/>*/}
                            <TagInput
                                name="formats"
                                label="Formats"
                                autocomplete={true}
                                suggestions={formats}
                            />
                        </Form>
                    </Popup>
                )
            },
            releaseStockUpdate: (release) => {
                return (
                    <Popup title={'Edit release'} fullscreen={true}>
                        <ReleaseStockEdit
                            release={release}
                            onSave={release => {
                                this.updateView();
                                this.closePopup('releaseStockUpdate', release.id);
                                this.updateView('index');
                            }}
                        />
                    </Popup>
                )
            },
            createPickList: () => {
                return (
                    <Popup title={'Create pick list'}>
                        <PickListCreate
                            onSave={list => {
                                this.updateView();
                                this.updateView('picker');
                                
                                this.closePopup('createPickList');
                                this.openPopup('viewPickList', list);
                                
                                ui.notify('Pick list created');
                            }}
                        />
                    </Popup>
                )
            },
            viewPickList: (list) => {
                return (
                    <Popup
                        title={list.location.name + ': Pick list'}
                        fullscreen={true}
                        padded={false}
                        header={list.status === 'pending' && (
                            <ButtonGroup>
                                <Button
                                    onClick={e => {
                                        http
                                            .post(API_HOST + API_ROOT + 'pick_lists/' + list.id + '/complete', {}, true)
                                            .then(response => {
                                                this.updateView();
                                                this.updateView('picker');
                                                
                                                this.closePopup('viewPickList');
                                                
                                                ui.notify('Pick list completed');
                                            })
                                    }}
                                    text={'Complete list'} />
                            </ButtonGroup>
                                )}
                    >
                        <PickItemsList list={list} />
                    </Popup>
                )
            },
        };

        this.state = {
            view: this.views.index,
            activeLocation: this.locations[0].key,
            isScanning: true,
            isLoading: false,
            formats: [],
            locations: [],
        };
    }

    updateActiveLocation(key) {

        let active = this.locations.find(location => location.key === key);
        this.setState({activeLocation: active.key});
    }

    toggleScanning() {

        this.setState({isScanning: !this.state.isScanning});
    }

    toggleLoading() {

        this.setState({isLoading: !this.state.isLoading});
    }

    findValue(object, key, value) {

        return Object.values(object).find(v => v[key] === parseInt(value));
    }

    componentDidMount() {
        this.fetchFormats();
    }

    fetchFormats() {
        http
            .request({url: API_HOST + API_ROOT + 'releases/formats'}, true)
            .then(response => {
                this.setState({
                    formats: response.result.items.map(format => {
                        return {
                            value: format.id + '',
                            label: format.abbreviation || format.name,
                        };
                    }),
                });
            });
    }
    
    render() {
        return (
            <>
                {this.state.view ? (
                    super.render()
                ) : (
                    <>
                        <Window
                            title="Inventory"
                            header={
                                <ButtonGroup>
                                    <EnumSwitch
                                        values={this.locations}
                                        activeKey={this.state.activeLocation}
                                        onItemSelect={key => {
                                            this.updateActiveLocation(key);
                                            this.toggleScanning();
                                        }}
                                    />
                                    <Button
                                        text="Search by Discogs id"
                                        onClick={e => this.openPopup('discogsIdSearch')}
                                    />
                                </ButtonGroup>
                            }
                            footer={
                                <ButtonGroup>
                                    <Button
                                        text="Cancel"
                                        modifiers={'alt'}
                                        onClick={e => this.updateView('index')}
                                    />
                                    <Button
                                        text="Add to stock"
                                        onClick={e => {
                                            this.props.checkoutInventory();
                                            this.updateView('index');
                                        }}
                                    />
                                </ButtonGroup>
                            }
                        >
                            <LoadingOverlay isLoading={this.state.isLoading} />
                            <Grid cols={'1fr auto'}>
                                <Window modifiers={['frame']}>
                                    <BarcodeScanner
                                        showErrors={false}
                                        defaultValues={this.props.products}
                                        isScanning={this.state.isScanning}
                                        onReleaseFound={release => {
                                            this.props.addProduct(release, this.state.activeLocation);
                                        }}
                                        onMultipleReleasesFound={(releases, barcode) => {
                                            releases.forEach(release => {
                                                this.props.addProduct(release, this.state.activeLocation);
                                            });
                                        }}
                                        onReleaseNotFound={barcode => {
                                            this.props.emptyInventory();
                                            this.toggleLoading();
                                            this.toggleScanning();
                                            
                                            http
                                                .get(API_HOST + API_ROOT + 'discogs/barcode/' + barcode, true)
                                                .then(response => {
                                                    let discogsRelease = response.result;
                                                    this.props.addProduct(discogsRelease);
                                                    this.toggleLoading();
                                                    
                                                    this.openPopup(
                                                        'discogsReleaseCreate',
                                                        discogsRelease,
                                                        discogsRelease.barcode
                                                    );
                                                })
                                                .catch(error => {
                                                    this.toggleLoading();
                                                    
                                                    if (error.message === 'release_not_found') {
                                                        ui.notify('Discogs release not found', 'error');
                                                    }
                                                })
                                                .catch(error => handleTokenErrors(error));
                                        }}
                                    />
                                    <div className="list">
                                        <div className="list__items">
                                            {Object.values(this.props.products).map((product, key) => (
                                                <InventoryListItem
                                                    model={product}
                                                    key={key}
                                                    onEdit={product =>
                                                        this.openPopup('productStockUpdate', product)
                                                    }
                                                    onDelete={product =>
                                                        this.props.deleteProduct(product)
                                                    }
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </Window>
                            </Grid>
                        </Window>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    products: state.inventory.products,
});

const mapDispatchToProps = {
    addProduct: addProduct,
    updateProductLocationAmount: updateProductLocationAmount,
    updateProductFormats: updateProductFormats,
    updateProduct: updateProduct,
    deleteProduct: deleteProduct,
    checkoutInventory: checkoutInventory,
    emptyInventory: emptyInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryModule);
