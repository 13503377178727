import React from 'react';
import Window from "../../window";
import Pagination from "../../pagination";
import List from "../../list";
import PickListRow from "../pick-list-row";
import HeaderColumn from "../../../atoms/header-column";
import Header from "../../header";
import StatusLabel from "../../../atoms/status-label";
import PickListItem from "./item";

class PickItemsList extends React.Component
{
    static defaultProps = {
        list: null,
        onViewList: () => {}
    };
    
    constructor(props) {
        super(props);
        
        this.state = {
            list: props.list
        };
    }
    
    render() {
        return (
            <Window footer={<Pagination listId="pick_lists_items"/>}>
                <List
                    id={'pick_lists_items'}
                    model={'pick_lists_items'}
                    selectable={false}
                    filters={{
                        filter_pick_list: [
                            {key: this.state.list.id}
                        ]
                    }}
                    header={(
                        <Header listId={'pick_lists_items'}>
                            <HeaderColumn title="Release" modifiers={'span-3'} />
                            <HeaderColumn modifiers={'span-3'} />
                            <HeaderColumn title="Webshop Quantity" modifiers={'span-2'} />
                            <HeaderColumn title="Current Quantity" modifiers={'span-2'} />
                            <HeaderColumn title="Requested Quantity" modifiers={'span-3'} />
                            <HeaderColumn modifiers={'span-3'} />
                            <HeaderColumn />
                        </Header>
                    )}
                    onItemClick={(list) => this.props.onViewList(list)}
                >
                    <PickListItem listCompleted={this.state.list.status === 'completed'} onViewList={(list) => this.props.onViewList(list)} />
                </List>
            </Window>
        );
    }
}

export default PickItemsList;